import React, { useEffect, useState } from "react";
import axios from "axios";
import { NODE_ROUTE_URI, PARTNER_URI } from "../../utils/apiUrl";
import { Box, Button } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PartnerServiceFeeSettingItem from "./PartnerServiceFeeSettingItem";
import PartnerServiceFeeSettingAddRow from "./PartnerServiceFeeSettingAddRow";
const PartnerServiceFeeSettingTable = ({ partnerId,
                                         ownerRole,
                                         shipmentTypeGroupId,
                                         isGlobal=false
}) => {

  const [serviceFeeSettingList, setServiceFeeSettingList] = useState([]);
  const [carrierOptionsForLTL, setCarrierOptionsForLTL] = useState([]);

  const [isAdding, setIsAdding] = useState(false); // State for showing add row

  const tableCells = isGlobal ? (
    <>
      <TableCell>Shipment Type</TableCell>
      <TableCell>Carrier</TableCell>
      <TableCell>Owner Role</TableCell>
      <TableCell>Service Fee Rate</TableCell>
      <TableCell>Tax Rate</TableCell>
      <TableCell>Min Service Fee</TableCell>
      <TableCell>Max Service Fee</TableCell>
      <TableCell align="left" ></TableCell>
      <TableCell align="left" ></TableCell>
    </>
  ) : (
    <>
      <TableCell>Shipment Type</TableCell>
      <TableCell>Carrier</TableCell>
      <TableCell>Service Fee Rate</TableCell>
      <TableCell>Tax Rate</TableCell>
      <TableCell>Min Service Fee</TableCell>
      <TableCell>Max Service Fee</TableCell>
      <TableCell align="left" ></TableCell>
      <TableCell align="left" ></TableCell>
    </>
  )

  const loadServiceFeeSettingList = async () => {
    const { data } = await axios.get(`${NODE_ROUTE_URI}/serviceFeeSetting/getServiceFeeSettingList`, {
      params: {
        partnerId: partnerId,
        ownerRole: ownerRole,
        shipmentTypeGroupId: shipmentTypeGroupId
      }
    });
    console.log("loadServiceFeeSettingList", loadServiceFeeSettingList);
    setServiceFeeSettingList(data);
  }

  useEffect(() => {
    // If Shipment Type Group is For Package Only, no need to load LTL Carriers
    if (shipmentTypeGroupId !== 5) {
      (async () => {
        const { data } = await axios.get(`${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`);
        setCarrierOptionsForLTL(data.map(v => v.account_base))
      })();
    }
  }, [shipmentTypeGroupId])

  useEffect(() => {
    (async () => {
      if (partnerId || isGlobal) {
        await loadServiceFeeSettingList();
      }
    })();
  }, [isGlobal, partnerId]);

  const handleAddRow = () => {
    setIsAdding(true);
  };

  const handleCancelAddRow = () => {
    setIsAdding(false);
  };

  useEffect(() => {
    console.log(`serviceFeeSettingList: ${JSON.stringify(serviceFeeSettingList, null, 4)}`);
  }, [serviceFeeSettingList]);

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>Shipment Type</TableCell>*/}
              {/*<TableCell>Carrier</TableCell>*/}
              {/*<TableCell>Service Fee Rate</TableCell>*/}
              {/*<TableCell>Tax Rate</TableCell>*/}
              {/*<TableCell>Min Service Fee</TableCell>*/}
              {/*<TableCell>Max Service Fee</TableCell>*/}
              {/*<TableCell align="left" ></TableCell>*/}
              {/*<TableCell align="left" ></TableCell>*/}
              {tableCells}
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceFeeSettingList.map((row) => {
                const {
                  shipment_type_group_id: shipmentTypeGroupId,
                  account_base: accountBase,
                  service_fee_rate: serviceFeeRate,
                  service_fee_tax_rate: serviceFeeTaxRate,
                  min_service_fee_charge: minServiceFee,
                  max_service_fee_charge: maxServiceFee,
                  owner_role: rowOwnerRole,
                  id
                } = row;
                return (
                  <PartnerServiceFeeSettingItem
                    serviceFeeRate={serviceFeeRate}
                    shipmentTypeGroupId={shipmentTypeGroupId}
                    id={id}
                    maxServiceFee={maxServiceFee}
                    serviceFeeTaxRate={serviceFeeTaxRate}
                    accountBase={accountBase}
                    minServiceFee={minServiceFee}
                    refreshList={loadServiceFeeSettingList}
                    key={id}
                    ownerRole={rowOwnerRole}
                    isGlobal={isGlobal}
                  />
                );
              }
            )
            }

            {/* Render new row for adding data */}
            {isAdding && (
              <PartnerServiceFeeSettingAddRow
                partnerId={partnerId}
                ownerRole={ownerRole}
                refreshList={loadServiceFeeSettingList}
                onCancel={handleCancelAddRow}
                carrierOptionsForLTL={carrierOptionsForLTL}
                shipmentTypeGroupId={shipmentTypeGroupId}
                isGlobal={isGlobal}
              />
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {/* Add Button */}
      {!isAdding && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddRow}
            isGlobal={isGlobal}
          >
            Add New Row
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PartnerServiceFeeSettingTable;
