import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Box, Button, Snackbar} from "@material-ui/core";
import {useForm} from "react-hook-form";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {green, red, blue, grey} from "@mui/material/colors";
import BackArrow from "../BackArrow";
import axios from "axios";
import {SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import PartnerServiceFeeSettingTable from "../ServiceFeeSetting/PartnerServiceFeeSettingTable";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    customButton: {
        backgroundColor: '#FD841F',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FD841F',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    },
    saveButton: {
        backgroundColor: '#54B435',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#54B435',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'
        },
        marginRight: '10px'
    },
    cancelButton: {
        backgroundColor: '#FF0032',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FF0032',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    }
}));

const EndUserDetails = () => {
        const classes = useStyles();

        const [endUserId] = useState(useParams().id)
        const [endUserDetails, setEndUserDetails] = useState({})
        const [updatedEndUserDetails, setUpdatedEndUserDetails] = useState({})
        const [canEdit, setCanEdit] = useState(false);

        const [companyList, setCompanyList] = useState([]);
        const [companyId, setCompanyId] = useState("");
        const [isSetCompany, setIsSetCompany] = useState(false);

        const {register, handleSubmit, control} = useForm();

        const [toastOpen, setToastOpen] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [successMessage, setSuccessMessage] = useState("");

        const [creditCard, setCreditCard] = useState(null);
        const [isAddCreditCard, setIsAddCreditCard] = useState(false);
        const [loadingCreditCard, setLoadingCreditCard] = useState(false);

        const handleToastClose = () => {
            setSuccessMessage("");
            setErrorMessage("");
            setToastOpen(false)
        }

        const getEndUserDetails = async () => {
            let requestURI = `${PARTNER_URI}/partner/getUserProfileByUserIdOrEmail`;
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURI,
                    params: {
                        userId: endUserId
                    }
                });
                const {data} = result
                console.log(data)
                setEndUserDetails(data)
                setUpdatedEndUserDetails(data)
            } catch (e) {
                console.log('error', e)
                return
            }
        }

        const getCompanyResult = async (companySearch) => {
            let requestURI = `${PARTNER_URI}/companies/searchCompanyList`;

            try {
                const result = await axios({
                    method: 'get',
                    url: requestURI,
                    params: {
                        query_string: companySearch
                    }
                })
                const companyInfo = [];
                const {data} = result;
                console.log(data)
                for (const value of data) {
                    const companyData = {
                        // label: `${value?.id}`,
                        label: `${value?.id || "N/A"} | ${value?.business_name || "N/A"}  | ${value?.email || "N/A"} | ${value?.phone || "N/A"} `,
                        value: value?.id
                    }
                    companyInfo.push(companyData)
                }

                // console.log(companyInfo)
                return companyInfo;

            } catch (e) {
                console.log(e);
            }
        }

        const setCompanyForUserProfile = async () => {
            let requestURI = `${PARTNER_URI}/companies/setCompanyForUserProfile`;
            let data = JSON.stringify({
                "companyId": `${companyId}`,
                "userId": `${endUserId}`,
                "userEmail": `${endUserDetails.email}`
            });

            try {
                await axios({
                    method: 'post',
                    url: requestURI,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                })
                setSuccessMessage("Successfully set company to user");
            } catch (e) {
                console.log(e);
                setErrorMessage(`Failed to add end user, ${e?.response?.data?.message}`);
            } finally {
                setToastOpen(true);
            }
        }

        const getValidCreditCardList = async () => {
            const requestURL = `${NODE_ROUTE_URI}/stripe/admin/validCreditCardList`;
            const params = {
                userId: endUserDetails.id,
                roleId: endUserDetails.role_id
            }
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURL,
                    params: params
                });
                console.log('[getValidCreditCardList] result', result);
                setCreditCard(result?.data?.[0]);
            } catch (e) {
                console.log('error', e.response);
            }
        }

        const deleteCreditCard = async () => {
            setLoadingCreditCard(true);
            const requestURL = `${NODE_ROUTE_URI}/stripe/admin/voidCardByCardId`;
            try {
                const result = await axios({
                    method: 'delete',
                    url: requestURL,
                    params: {
                        cardId: creditCard?.id,
                        userId: creditCard?.user_profile_id,
                        roleId: creditCard?.owner_role
                    }
                })
                console.log('result', result);
                setSuccessMessage("Successfully removed credit card");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (e) {
                console.log(e.response);
                setErrorMessage("Failed to remove credit card");
            } finally {
                setToastOpen(true);
                setLoadingCreditCard(false);
            }
        }

        useEffect(() => {
            getEndUserDetails()
        }, []);

        useEffect(() => {
            (async () => {
                if (endUserDetails?.id) {
                    await getValidCreditCardList();
                }
            })()
        }, [endUserDetails]);

// Reverts all Textfields/inputs to intial, turns canEdit off
        const cancelChanges = () => {
            setCanEdit(false);
            setUpdatedEndUserDetails(endUserDetails);
        };

        // todo: call api save updated user
        const saveUpdatedEndUser = () => {

        }

        const setCompany = () => {
            setCompanyForUserProfile()
        }

        const handleAddCreditCard = () => {
            setIsAddCreditCard(true);
        }

        const handleCancelCreditCard = () => {
            setIsAddCreditCard(false);
        }

        return (
            <div>
                <BackArrow link={"/end-user-list"}/>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={toastOpen}
                    onClose={handleToastClose}
                    autoHideDuration={3000}
                    message="Submit Transaction"
                >
                    {(() => {
                        if (errorMessage !== "") {
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="error"
                                    sx={{width: "100%"}}
                                >
                                    Error!
                                    <hr/>
                                    {errorMessage}
                                </Alert>
                            );
                        }
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="success"
                                sx={{width: "100%"}}
                            >
                                {successMessage}
                            </Alert>
                        );
                    })()}
                </Snackbar>
                <Box maxWidth="70%" margin="0 auto" paddingBottom={"200px"}>
                    <Box mt={2}>
                        <h1>
                            {(endUserDetails.firstname && endUserDetails.lastname)
                                ? `${endUserDetails.firstname} ${endUserDetails.lastname}`
                                : "Loading Details"}
                        </h1>
                        {canEdit ? (
                            <>
                                <Button
                                    style={{
                                        backgroundColor: green[500],
                                        color: "white",
                                    }}
                                    variant="contained"
                                    onClick={saveUpdatedEndUser}
                                >
                                    Save
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: red[500],
                                        marginLeft: "20px",
                                        color: "white",
                                    }}
                                    variant="contained"
                                    onClick={cancelChanges}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: blue[500],
                                    color: "white",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => setCanEdit(true)}
                            >
                                Edit Basic Info
                            </Button>
                        )}
                        <table className={classes.table}>
                            <thead>
                            <tr>
                                <th className={classes.idTH} width="20%"></th>
                                <th className={classes.idTH} width="100%"></th>
                                {/* <th className={classes.idTH} width="10%"></th> */}
                            </tr>
                            </thead>
                            <tbody className={classes.tbody}>
                            <tr>
                                <td>
                                    <strong>First Name</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            id="outlined-basic"
                                            value={
                                                updatedEndUserDetails.firstname
                                                    ? updatedEndUserDetails.firstname
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setUpdatedEndUserDetails((prev) => ({
                                                    ...prev,
                                                    firstname: e.target.value,
                                                }));
                                            }}
                                            // variant={canEdit ? "outlined" : "filled"}
                                            variant="outlined"
                                            // {...register("businessName")}
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Last Name</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            id="outlined-basic"
                                            value={
                                                updatedEndUserDetails.lastname
                                                    ? updatedEndUserDetails.lastname
                                                    : ""
                                            }
                                            variant="outlined"
                                            // {...register("normalizedBusinessName")}
                                            onChange={(e) => {
                                                setUpdatedEndUserDetails((prev) => ({
                                                    ...prev,
                                                    lastname: e.target.value,
                                                }));
                                            }}
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Phone</strong>
                                </td>
                                <td>
                                    <Box flex="45%" width="100%">
                                        <TextField
                                            id="outlined-basic"
                                            value={
                                                updatedEndUserDetails.phone
                                                    ? updatedEndUserDetails.phone
                                                    : ""
                                            }
                                            variant="outlined"
                                            // {...register("gstNumber")}
                                            onChange={(e) => {
                                                setUpdatedEndUserDetails((prev) => ({
                                                    ...prev,
                                                    phone: e.target.value,
                                                }));
                                            }}
                                            fullWidth
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Box flex="45%" width="100%" mt={2} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Autocomplete
                                sx={{width: 8 / 10}}
                                disablePortal
                                options={companyList}
                                disabled={!isSetCompany}
                                fullWidth
                                filterOptions={(options, state) => options}
                                getOptionLabel={(option) => option.label}
                                {...register("companyId",)}
                                onChange={(e, value) => {
                                    setCompanyId(value.value);
                                }}
                                onInputChange={async (e, value) => {
                                    setCompanyId("");
                                    const result = await getCompanyResult(value);
                                    // console.log(result);
                                    setCompanyList(result);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        id="outlined-basic"
                                        label=
                                            {
                                                endUserDetails?.company_id ? `Current: #${endUserDetails?.company_id} | ${endUserDetails?.company}` : "No Admin Found"
                                            }
                                        variant="outlined"
                                        {...register("companyId")}
                                        required
                                        fullWidth
                                        {...params}
                                    />}
                            />
                            {isSetCompany ? (
                                <Box sx={{display: 'flex'}}>
                                    <Button
                                        className={classes.saveButton}
                                        onClick={setCompany}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className={classes.cancelButton}
                                        onClick={() => setIsSetCompany(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            ) : (
                                <Button
                                    className={classes.customButton}
                                    onClick={() => setIsSetCompany(true)}
                                >
                                    Set Company
                                </Button>
                            )}
                        </Box>
                        <Box>
                            <h2>Credit Card</h2>
                            <table className={classes.table}>
                                <thead>
                                <tr>
                                    <th className={classes.idTH} width="20%"></th>
                                    <th className={classes.idTH} width="100%"></th>
                                </tr>
                                </thead>
                                <tbody className={classes.tbody}>
                                <tr>
                                    <td>
                                        <strong>Card Type</strong>
                                    </td>
                                    <td>
                                        <Box flex="45%" width="100%">
                                            <TextField
                                                value={creditCard?.card_type}
                                                id="outlined-basic"
                                                variant="outlined"
                                                fullWidth
                                                disabled={!canEdit}
                                            />
                                        </Box>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Card Number (last four digits)</strong>
                                    </td>
                                    <td>
                                        <Box flex="45%" width="100%">
                                            <TextField
                                                value={creditCard?.card_last_four_digits}
                                                id="outlined-basic"
                                                variant="outlined"
                                                fullWidth
                                                disabled={!canEdit}
                                            />
                                        </Box>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Expiry Date</strong>
                                    </td>
                                    <td>
                                        <Box flex="45%" width="100%">
                                            <TextField
                                                value={creditCard?.card_expiry}
                                                id="outlined-basic"
                                                variant="outlined"
                                                fullWidth
                                                disabled={!canEdit}
                                            />
                                        </Box>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {
                                creditCard ?
                                    <LoadingButton
                                        style={{
                                            backgroundColor: red[500],
                                            fontSize: "10px",
                                            color: "white",
                                            margin: '5px',
                                        }}
                                        variant="contained"
                                        onClick={deleteCreditCard}
                                        loading={loadingCreditCard}
                                    >
                                        Remove Credit Card
                                    </LoadingButton>
                                    :
                                    <Button
                                        style={{
                                            backgroundColor: isAddCreditCard ? grey[500] : green[500],
                                            fontSize: "10px",
                                            color: "white",
                                            margin: '5px',
                                        }}
                                        variant="contained"
                                        onClick={handleAddCreditCard}
                                        disabled={isAddCreditCard}
                                    >
                                        Add Credit Card
                                    </Button>
                            }
                            {
                                isAddCreditCard && <CreditCardSetup userId={endUserDetails?.id} roleId={endUserDetails?.role_id}
                                                                    handleCancelCreditCard={handleCancelCreditCard}/>
                            }
                            {/*Service Fee Settings*/}
                            <>
                                <h1>Service Fee Setting</h1>
                                <Box>
                                    <PartnerServiceFeeSettingTable partnerId={endUserDetails?.id} ownerRole={1} />
                                </Box>
                            </>
                        </Box>
                    </Box>
                </Box>
            </div>
        );
    }
;

export default EndUserDetails;