import React, { useEffect, useState } from "react";
import { Button, FormControl, Select, TableCell, TableRow, TextField } from "@material-ui/core";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";
import { MenuItem } from "@mui/material";

const PartnerServiceFeeSettingAddRow = ({
                                          partnerId,
                                          ownerRole: initializeOwnerRole,
                                          refreshList,
                                          onCancel,
                                          carrierOptionsForLTL = [],
                                          shipmentTypeGroupId: initialShipmentTypeGroupId,
                                          isGlobal=false
                                        }) => {
  // Separate state variables for each form field
  const [shipmentTypeGroupId, setShipmentTypeGroupId] = useState(initialShipmentTypeGroupId || 5); // Default to "Package"
  const [ownerRole, setOwnerRole] = useState(initializeOwnerRole || 3);
  const [shipmentTypeGroupMenuItems, setShipmentTypeGroupMenuItems] = useState([
    {
      value: 1,
      name: "LTL"
    },
    {
      value: 5,
      name: "Package"
    }]);
  const [ownerRoleGroupMenuItems, setOwnerRoleTypeGroupMenuItems] = useState([
    {
      value: 3,
      name: "Partner"
    },
    {
      value: 1,
      name: "End User"
    }]);

  const [accountBase, setAccountBase] = useState("");
  const [serviceFeeRate, setServiceFeeRate] = useState("");
  const [serviceFeeTaxRate, setServiceFeeTaxRate] = useState(0.05);
  const [minServiceFee, setMinServiceFee] = useState(0);
  const [maxServiceFee, setMaxServiceFee] = useState(5000);

  useEffect(() => {
    let menuItems = [];

    if (+initialShipmentTypeGroupId === 1) {
      menuItems = [
        {
          value: 1,
          name: "LTL"
        }
      ];
    } else if (+initialShipmentTypeGroupId === 5) {
      menuItems = [
        {
          value: 5,
          name: "Package"
        }
      ];
    } else {
      menuItems = [
        {
          value: 1,
          name: "LTL"
        },
        {
          value: 5,
          name: "Package"
        }];
    }
    setShipmentTypeGroupMenuItems(menuItems);

  }, [initialShipmentTypeGroupId]);

  useEffect(() => {
    let menuItems = [];

    if (+initializeOwnerRole === 1) {
      menuItems = [
        {
          value: 1,
          name: "End User"
        }
      ];
    } else if (+initializeOwnerRole === 3) {
      menuItems = [
        {
          value: 3,
          name: "Partner"
        }
      ];
    } else {
      menuItems = [
        {
          value: 1,
          name: "End User"
        },
        {
          value: 3,
          name: "Partner"
        }];
    }
    setOwnerRoleTypeGroupMenuItems(menuItems);
  }, [initializeOwnerRole]);


  // Array A for Package and Array B for LTL
  const carrierOptionsForPackage = ["UPS", "FedEx", "Canpar", "CanadaPost"];

  const handleSaveNewRow = async () => {
    try {
      await axios.post(`${NODE_ROUTE_URI}/serviceFeeSetting/addServiceFeeSetting`, {
        partnerId,
        shipmentTypeGroupId,
        accountBase,
        serviceFeeRate,
        serviceFeeTaxRate,
        minServiceFee,
        maxServiceFee,
        ownerRole
      });
      refreshList();
      onCancel(); // Close the form after saving
    } catch (error) {
      console.error("Error adding new row:", error);
    }
  };

  // Determine which carrier options to use based on the selected shipment type
  const carrierOptions =
    shipmentTypeGroupId === 5
      ? carrierOptionsForPackage
      : carrierOptionsForLTL;

  return (
    <TableRow>
      <TableCell>
        <FormControl fullWidth>
          <Select
            value={shipmentTypeGroupId}
            onChange={(e) => setShipmentTypeGroupId(e.target.value)}
            fullWidth
          >
            {
              shipmentTypeGroupMenuItems?.map(menuItem => {
                const { value, name } = menuItem;
                return (
                    <MenuItem value={value}>{name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl fullWidth>
          <Select
            value={accountBase}
            onChange={(e) => setAccountBase(e.target.value)}
            fullWidth
          >
            {carrierOptions.map((carrier) => (
              <MenuItem key={carrier} value={carrier}>
                {carrier}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {
        isGlobal ?
          (
            <TableCell>
              <FormControl fullWidth>
                <Select
                  value={ownerRole}
                  onChange={(e) => setOwnerRole(e.target.value)}
                  fullWidth
                >
                  {
                    ownerRoleGroupMenuItems?.map(menuItem => {
                      const { value, name } = menuItem;
                      return (
                        <MenuItem value={value}>{name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </TableCell>
          )
          : (
            <>
            </>
          )
      }

      <TableCell>
        <TextField
          label="Service Fee Rate"
          value={serviceFeeRate}
          onChange={(e) => setServiceFeeRate(e.target.value)}
          type="number"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Tax Rate"
          value={serviceFeeTaxRate}
          onChange={(e) => setServiceFeeTaxRate(e.target.value)}
          type="number"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Min Service Fee"
          value={minServiceFee}
          onChange={(e) => setMinServiceFee(e.target.value)}
          type="number"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Max Service Fee"
          value={maxServiceFee}
          onChange={(e) => setMaxServiceFee(e.target.value)}
          type="number"
          fullWidth
        />
      </TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveNewRow}
        >
          Add
        </Button>
      </TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PartnerServiceFeeSettingAddRow;
