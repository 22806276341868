import {Button, Container, Grid, InputAdornment, MenuItem, Select, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import {Pagination} from "@mui/lab";
import {LTLOrderListTable} from "./LTLOrderListTable";
import {useHistory, useLocation} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderList = () => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const history = useHistory();

    const [type, setType] = useState('orderList');
    const [searchString, setSearchString] = useState(null);
    const [partner, setPartner] = useState('default');
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [partnerList, setPartnerList] = useState([]);

    const classes = useStyles();

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const searchPartner = async (queryString) => {
        const requestURL = `${PARTNER_URI}/elasticsearch/partner/searchPartners`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: queryString,
                }
            });
            console.log('[partner list] - result', result);
            return result?.data?.result;
        } catch (e) {
            console.log(e.response);
        }
    }

    const getLTLOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/admin/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                    partnerId: (partner === 'default' || partner === 'all') ? '' : partner
                }
            });
            console.log('[shipping order list ltl] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getLTLOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/admin/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                    partnerId: (partner === 'default' || partner === 'all') ? '' : partner
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleSearchShippingOrder = () => {
        getLTLOrderList();
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getLTLOrderList();
        }
    }

    const handleChange = (event, value) => {
        console.log(value);
        if (value) {
            setPartner(value?.id)
        } else {
            setPartner('default');
        }
    };

    const handleInputChange = async (event, value) => {
        console.log('value', value);
        if (value) {
            const result = await searchPartner(value);
            setPartnerList(result);
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getLTLOrderListByPage(page - 1);
    };

    const handleAddOrder = () => {
        history.push('/v2/ltl-order-list/add');
    }

    const handleChangeType = (value) => {
        console.log('[handleChangeType] value', value);
        if (value === 'addOrder') {
            history.push({
                pathname: '/v2/ltl-order-list/add',
                state: value
            })
        } else if (value !== 'orderList') {
            history.push({
                pathname: '/v2/ltl-quote-list',
                state: value
            })
        } else {
            setType(value);
        }
    }

    useEffect(() => {
        (async () => {
            await getAllPartnerList();
            await getLTLOrderList();
        })()
    }, []);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quickQuote'}
                            onClick={() => handleChangeType('quickQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quick Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quoteList'}
                            onClick={() => handleChangeType('quoteList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quote List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addQuote'}
                            onClick={() => handleChangeType('addQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'orderList'}
                            onClick={() => handleChangeType('orderList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Order List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addOrder'}
                            onClick={() => handleChangeType('orderOrder')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Order
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'setting'}
                            onClick={() => handleChangeType('setting')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Service Fee Setting
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    LTL Order List
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={partnerList}
                                        getOptionLabel={option => option?.business_name}
                                        filterOptions={(options, state) => options}
                                        fullWidth
                                        renderInput={(params) => <TextField
                                            {...params}
                                            className={classes.smallInput}
                                            placeholder='Filter By Partner'
                                        />}
                                        onInputChange={handleInputChange}
                                        onChange={handleChange}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '14px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option?.business_name}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                    {/*<Select*/}
                                    {/*    value={partner}*/}
                                    {/*    size='small'*/}
                                    {/*    placeholder='Select Partner'*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*>*/}
                                    {/*    <MenuItem disabled value='default'>*/}
                                    {/*        <em>Select Partner</em>*/}
                                    {/*    </MenuItem>*/}
                                    {/*    <MenuItem value='all'>All</MenuItem>*/}
                                    {/*    {*/}
                                    {/*        partnerList?.map((partner, index) => (*/}
                                    {/*            <MenuItem key={index} value={partner?.id}>*/}
                                    {/*                {partner?.business_name}*/}
                                    {/*            </MenuItem>*/}
                                    {/*        ))*/}
                                    {/*    }*/}
                                    {/*</Select>*/}
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <TextField
                                value={searchString}
                                fullWidth
                                size='small'
                                onInput={handleSearchString}
                                onKeyDown={handleSearchShippingOrderByKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleSearchShippingOrder}
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{root: classes.customTextField}}
                                // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                placeholder='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                            />
                        </Grid>
                        <Grid item md={2} xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleSearchShippingOrder}
                                >
                                    <Typography
                                        style={{textTransform: 'none', fontSize: '16px'}}
                                    >
                                        Search
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        {/*<Grid item md={12} xs={12}>*/}
                        {/*    <Box sx={{*/}
                        {/*        display: 'flex',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        height: '100%',*/}
                        {/*        justifyContent: 'flex-end'*/}
                        {/*    }}>*/}
                        {/*        <Button*/}
                        {/*            variant='contained'*/}
                        {/*            sx={{*/}
                        {/*                backgroundColor: '#1D8B45',*/}
                        {/*                height: '100%',*/}
                        {/*                "&:hover": {*/}
                        {/*                    backgroundColor: '#1D8B45',*/}
                        {/*                    filter: 'brightness(0.9)'*/}
                        {/*                },*/}
                        {/*            }}*/}
                        {/*            onClick={handleAddOrder}*/}
                        {/*        >*/}
                        {/*            <Typography*/}
                        {/*                style={{textTransform: 'none', fontSize: '16px'}}*/}
                        {/*            >*/}
                        {/*                Add Order*/}
                        {/*            </Typography>*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}
                    </Grid>
                    {
                        loading ?
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            error ?
                                <Box sx={styles.ShippingOrderList}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        Sorry it currently encounter some issues, please try again later
                                    </Typography>
                                </Box>
                                :
                                orderData?.length === 0 ?
                                    <Box sx={styles.ShippingOrderList}>
                                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                            No result
                                        </Typography>
                                    </Box>
                                    :
                                    <Box sx={styles.ShippingOrderList}>
                                        <LTLOrderListTable
                                            order={orderData}
                                        />
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                    }
                </Box>
            </Box>
        </Container>
    )
}
